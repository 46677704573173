import { useState, useEffect } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";

const UserQuiz = () => {
  const [totalBalance, setTotalBalance] = useState(0);
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    token
      .get("/quiz-category/get-all-category/player")
      .then((res) => {
        if (res.data.status == "success") {
          setCategories(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          const balanceFixed = res?.data?.data?.availableBalance.toFixed(2);
          setTotalBalance(balanceFixed);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);



  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">Quiz</h2>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-12">
              <div class="row">
                <div class="col-xl-4 col-sm-6 col-6 ">
                  <div class="card banking-card pull-up pull-up">
                    <div class="card-header border-0 flex-wrap pb-0">
                      <div class="revenue-date text-light">
                        <span>Total Balance</span>
                        <h4>
                          {" "}
                          ₹
                          {totalBalance < 10
                            ? "0" + totalBalance
                            : totalBalance}
                        </h4>
                      </div>
                      <div class="setting bg-primary">
                        <i class="bi bi-cash-stack text-light"></i>
                      </div>
                    </div>
                    <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                  </div>
                </div>

                <div class="col-xl-4 col-sm-6 col-6">
                  <div class="card banking-card pull-up pull-up">
                    <div class="card-header border-0 flex-wrap pb-0">
                      <div class="revenue-date text-light">
                        <span> Total Win</span>
                        <h4> 20540.00</h4>
                      </div>
                      <div class="setting bg-success">
                        <i class="bi bi-trophy text-light"></i>
                      </div>
                    </div>
                    <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                  </div>
                </div>

                <div class="col-xl-4 col-sm-6 col-6">
                  <div class="card banking-card pull-up">
                    <div class="card-header border-0 flex-wrap pb-0">
                      <div class="revenue-date text-light">
                        <span>Quiz Play</span>
                        <h4> 10</h4>
                      </div>
                      <div class="setting bg-warning">
                        <i class="bi bi-collection-play text-light"></i>
                      </div>
                    </div>
                    <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                  </div>
                </div>

                {categories?.map((elem, index) => {
                  return (
                    <div
                      key={index}
                      class="col-lg-4 col-12"
                      onClick={() => {
                        navigate(`/user/dfficuly-level/${elem._id}`);
                      }}
                    >
                      <div class="card quiz-cat card-box pull-up">
                        <div class="card-body text-center pt-3">
                          <div className="imgquiz">
                            <img
                              alt="Category-Image"
                              src={
                                process.env.REACT_APP_API_URL.slice(
                                  0,
                                  process.env.REACT_APP_API_URL.length - 3
                                ) +
                                "/" +
                                elem?.image
                              }
                            />
                          </div>
                          <h6 class="mb-0 m-auto">{elem?.name}</h6>
                          <div class="mt-2 text-light fs-8">
                            {elem?.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div class="col-xl-3 col-xxl-4">
              <div class="row"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserQuiz;
