import { create } from "zustand";
import { isExpired, decodeToken } from "react-jwt";
import { io } from "socket.io-client";
import { getUserIDFromToken } from "./function";

export const User = create((set) => ({
  Authorized:
    sessionStorage.getItem("nvshoppe") === null ||
    isExpired(
      sessionStorage.getItem("nvshoppe") ||
        (!decodeToken(sessionStorage.getItem("nvshoppe")) &&
          decodeToken(sessionStorage.getItem("nvshoppe")).unique_name === "")
    )
      ? { Auth: false, user: {} }
      : { Auth: true, user: {} },
  setAuth: (e) => set(() => ({ Authorized: { Auth: true, user: e } })),
  Destroy: () => set(() => ({ Authorized: { Auth: false, user: {} } })),
}));

let socketId = "";

if (sessionStorage.getItem("token")) {
  socketId = {
    query: {
      userId: getUserIDFromToken(),
    },
  };
}

export const socket = io.connect("https://rocketup-app-1.onrender.com/", socketId);

window.addEventListener("online", function () {
  socket.emit("cameBackOnWebsite", getUserIDFromToken());
  socket.off("cameBackOnWebsite");
});

window.addEventListener("offline", function () {
  socket.emit("leftFromWebsite", getUserIDFromToken());
  socket.off("leftFromWebsite");
});
