import { useEffect, useState } from "react";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { humanReadableTimeAndDate } from "../../../Helper/function";
import TablePlaceholder from "../../Components/TablePlaceholder";
import { MDBDataTable } from "mdbreact";
import { io } from "socket.io-client";

export default function DiceRequest() {
  const [diceRequest, setDiceRequest] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);

  function fetchRequestData() {
    setLoadingTable(true);
    token
      .get("/dice/get-all-requests")
      .then((res) => {
        if (res.data.status == "success") {
          setDiceRequest(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        setLoadingTable(false);
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  useEffect(() => {
    fetchRequestData();
  }, []);


  function responseToRequest(resp, requestId) {
    const socket = io.connect("http://localhost:5100");
    token
      .put("/dice/response-request", { response: resp, requestId: requestId })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res.data.message, {
            position: "top-left",
            autoClose: 900,
          });

          if (resp == 1 && res.data.message == "Game request accepted") {
            socket.emit("requestAccepted", requestId);
          }
        } else {
          toast.error(res.data.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
        fetchRequestData();
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  const table = {
    columns: [
      {
        label: "S.No",
        field: "index",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 150,
      },
      {
        label: "Request Date",
        field: "requestDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Respond Date",
        field: "respondDate",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],

    rows: [],
  };

  function requestStatus(status) {
    if (status == -1) {
      return <span className="text-danger">Rejected</span>;
    } else if (status == 1) {
      return <span className="text-success">Accepted</span>;
    } else {
      return <span className="text-warning">Pending</span>;
    }
  }

  function actionButton(status, id) {
    if (status) {
      return (
        <>
          <button className="btn  btn-sm btn-success me-2" disabled>
            Accept
          </button>
          <button className="btn  btn-sm btn-danger" disabled>
            Reject
          </button>
        </>
      );
    } else {
      return (
        <>
          <button
            className="btn btn-sm btn-success me-2"
            onClick={() => {
              responseToRequest(1, id);
            }}
          >
            Accept
          </button>
          <button
            className="btn  btn-sm btn-danger"
            onClick={() => {
              responseToRequest(-1, id);
            }}
          >
            Reject
          </button>
        </>
      );
    }
  }

  diceRequest.map((elem, i) => {
    table.rows.push({
      index: i + 1,
      name: elem.senderId.name,
      amount: elem.amount,
      requestDate: humanReadableTimeAndDate(elem.createdAt),

      respondDate:
        elem.status != 0 ? humanReadableTimeAndDate(elem.updatedAt) : null,

      status: requestStatus(elem.status),
      action: actionButton(elem.responded, elem._id),
    });
  });

  return (
    <>
      <div class="page-titles">
        <div className="container-fluid">
          <h1 className="text-light">Dice Game Requests</h1>
        </div>
      </div>
      <div class="content-body">
        <div class="container-fluid">
          <div class="row ">
            <div class="col-lg-12">
              <div class="card">
                <div className="p-3">
                  {!loadingTable ? (
                    <div class="table table-responsive mbdtable diceRequest-table">
                      <MDBDataTable bordered small data={table} />
                    </div>
                  ) : null}

                  {loadingTable ? (
                    <table class="table table-responsive ">
                      <thead>
                        <tr className="border">
                          <th className="border" scope="col">
                            S.No
                          </th>
                          <th className="border" scope="col">
                            {" "}
                            Name
                          </th>
                          <th className="border" scope="col">
                            Amount
                          </th>
                          <th className="border" scope="col">
                            Request Date
                          </th>
                          <th className="border" scope="col">
                            Respond Date
                          </th>
                          <th className="border" scope="col">
                            Status
                          </th>
                          <th className="border" scope="col">
                            Action
                          </th>
                        </tr>
                      </thead>

                      <TablePlaceholder colNum={7} />
                    </table>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
