import axios from "axios";

const token = axios?.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: sessionStorage?.getItem("token") },
});

token.interceptors.request.use(
  (config) => {
    config.headers["Authorization"] = sessionStorage.getItem("token");
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

token.interceptors.response.use(
  (response) => {
    if (response.status === 200 && response.data.Status === 3) {
      // window.location.href = '/';
      //   sessionStorage.clear();
    }
    return response;
  },
  (error) => {
    // window.location.href = '/';
    if (error.response.status === 401) {
      // sessionStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default token;
