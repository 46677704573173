import { BrowserRouter, useNavigate, useRoutes } from "react-router-dom";
import Reoute from "./View/Routes";
import { createContext, useState } from "react";
function App() {
  const TimerContext = createContext();
  const [timer, setTimer] = useState(30);

  const Routes = () => {
    return useRoutes(Reoute);
  };

  return (
    <>
      <TimerContext.Provider value={timer}>
        <BrowserRouter>
          <Routes />
        </BrowserRouter>
      </TimerContext.Provider>
    </>
  );
}

export default App;
