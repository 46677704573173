import React, { useState } from 'react';

function Spin() {
    const [isSpinning, setIsSpinning] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);

  const segments = ['Prize 1', 'Prize 2', 'Prize 3', 'Prize 4', 'Prize 5'];

  const spinWheel = () => {
    if (isSpinning) return; // Prevent multiple spins

    setIsSpinning(true);
    const randomIndex = Math.floor(Math.random() * segments.length);
    const rotationDegree = 360 * 3 + randomIndex * (720 / segments.length); // 3 full spins + random segment

    setTimeout(() => {
      setSelectedIndex(randomIndex);
      setIsSpinning(false);
    }, 4000); // 4-second animation duration

    document.documentElement.style.setProperty('--rotation', rotationDegree + 'deg');
  };
  return (
<><div class="page-titles">
    <div className="container-fluid">
      <div class="sub-dz-head">
        <div class="d-flex align-items-center dz-head-title">
          <h2 class="text-white m-0">Spin Wheel</h2>
        </div>
      </div>
    </div>
  </div>


  <div class="content-body">
   <div class="container-fluid">
      <div class="row justify-content-center">
         <div class="col-lg-7 ">
            <div class="card">
               <div class="card-header">
                  <h4 class="card-title"> Spin </h4>
                  <h4 class="card-title"> Game Balance : 90/INR</h4>
                 
               </div>
               <div class="card-body">
               <div className="spin-wheel-container">
      <div className={`wheel ${isSpinning ? 'spinning' : ' spin2'}`}>
        <img src='/images/spin1.png' alt=''/>
      
      </div>
      <button onClick={spinWheel} disabled={isSpinning}>
        Spin the Wheel
      </button>
      {selectedIndex !== null && <div className="result">You won: {segments[selectedIndex]}</div>}
    </div>
               </div>
            </div>
         </div>
      </div>
     
   </div>
</div>









    


    </>

  )
}

export default Spin
