import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { User } from "../../../Helper/Variables";
import token from "../../../Helper/Token";
import { Link } from "react-router-dom";
const Dashbord = () => {
  const Info = User();
  const [dashboardData, setDashboardData] = useState({
    refferalIncome: 0.0,
    totalDeposite: 0.0,
    totalFundTransfer: 0.0,
    totalWithdrawal: 0.0,
    availableBalance: 0.0,
    userId: "Fetching user id",
  });
  const [newsData, setNewsData] = useState([]);
  const [balanceKMT, setBalanceKMT] = useState(0.0);
  const [teamMembers, setTeamMembers] = useState([]);
  const [onlineMembers, setOnlineMembers] = useState([]);

  useEffect(() => {
    token
      .get(`/news/get-all-news`)
      .then((res) => {
        if (res.data.status == "success") {
          setNewsData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    token
      .get("/user/dashboard")
      .then((res) => {
        if (res.data.status == "success") {
          Info.setAuth(res.data.data);
          setDashboardData(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    if (sessionStorage.getItem("KMT_Token")) {
      fetchKMTBalance();
    }
  }, []);

  useEffect(() => {
    token
      .get("/user/get-team-member")
      .then((res) => {
        if (res.data.status == "success") {
          setTeamMembers(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    token
      .get("/user/get-online-member")
      .then((res) => {
        if (res.data.status == "success") {
          setOnlineMembers(res.data.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  async function fetchKMTBalance() {
    try {
      const res = await fetch("https://apikmt.mnbsoft.co.in/api/gamebalance", {
        method: "get",
        headers: {
          "Content-Type": "application/json",
          "X-Auth-Token": sessionStorage.getItem("KMT_Token"),
        },
      });
      const data = await res.json();

      setBalanceKMT(data?.data?.balance);
    } catch (error) {
      toast.error("Internal Server Error.", {
        position: "top-right",
        autoClose: 900,
      });
    }
  }

  const Copy = (e) => {
    navigator.clipboard.writeText(e.currentTarget.value);
    toast.success("Copied.", { position: "top-right", autoClose: 800 });
  };
  return (
    <>
      <div id="main-wrappe">
        <div class="page-titles">
          <div className="container-fluid">
            <div class="sub-dz-head">
              <div class="d-flex align-items-center dz-head-title">
                <h2 class="text-white m-0">Dashboard</h2>
              </div>
            </div>
          </div>
        </div>
        <div class="content-body">
          <div class="container-fluid">
            <div>
              <marquee>
                <p>
                  {newsData.map((elem) => {
                    return (
                      <span>
                        <span className="text-danger me-2">
                          {elem?.newsTitle}
                        </span>
                        <span className="text-light me-5">{elem?.news}</span>
                      </span>
                    );
                  })}
                </p>
              </marquee>
            </div>
            <div class="row">
              <div class="col-xl-3 col-6">
                <div class="card card-box bg-secondary pull-up">
                  <div class="card-header border-0 pb-0">
                    <div class="chart-num">
                      <p>
                        <i class="fa-solid fa-sort-down me-2"></i>
                        Game Balance
                      </p>
                      <h2 class="font-w600 mb-0">
                        ₹{dashboardData?.availableBalance.toFixed(2)}
                      </h2>
                    </div>
                    <div class="dlab-swiper-circle">
                      <div className="img-icon text-secondary">
                        <i class="bi bi-joystick"></i>
                      </div>
                    </div>
                  </div>

                  <div class="card-body d-none d-sm-block p-0">
                    <div
                      id="widgetChart1"
                      class="chart-primary"
                      style={{ minHeight: "70px" }}
                    >
                      <div id="apexchartskj2vn6y2">
                        <img
                          alt="image"
                          width="auto"
                          src="/images/wavesvg.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {sessionStorage.getItem("KMT_Token") ? (
                <div class="col-xl-3 col-6">
                  <div class="card card-box bg-secondary card pull-up">
                    <div class="card-header border-0 pb-0">
                      <div class="chart-num">
                        <p>
                          <i class="fa-solid fa-sort-down me-2"></i>
                          KMT Meta Balance
                        </p>
                        <h2 class="font-w600 mb-0">
                          ₹{balanceKMT?.toFixed(2)}
                        </h2>
                      </div>
                      <div class="dlab-swiper-circle">
                        <div className="img-icon text-secondary">
                          <i class="bi bi-diagram-3-fill"></i>
                        </div>
                      </div>
                    </div>

                    <div class="card-body d-none d-sm-block p-0">
                      <div
                        id="widgetChart1"
                        class="chart-primary"
                        style={{ minHeight: "70px" }}
                      >
                        <div id="apexchartskj2vn6y2">
                          <img
                            alt="image"
                            width="auto"
                            src="/images/wavesvg.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div class="col-xl-3 col-6">
                <div class="card card-box bg-dark pull-up">
                  <div class="card-header border-0 pb-0">
                    <div class="chart-num">
                      <p>
                        <i class="fa-solid fa-sort-down me-2"></i>
                        Total Deposit
                      </p>
                      <h2 class="font-w600 mb-0">
                        ₹{dashboardData?.totalDeposite.toFixed(2)}
                      </h2>
                    </div>

                    <div class="dlab-swiper-circle">
                      <div className="img-icon text-dark">
                        <i class="bi bi-sort-down"></i>
                      </div>
                    </div>
                  </div>
                  <div class="card-body d-none d-sm-block p-0">
                    <div
                      id="widgetChart1"
                      class="chart-primary"
                      style={{ minHeight: "70px" }}
                    >
                      <div id="apexchartskj2vn6y2">
                        <img
                          alt="image"
                          width="auto"
                          src="/images/wavesvg.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-6 ">
                <div class="swiper-slide">
                  <div class="card card-box bg-warning  pull-up">
                    <div class="card-header border-0 pb-0">
                      <div class="chart-num">
                        <p>
                          <i class="fa-solid fa-sort-down me-2"></i>
                          Total Withdrawal
                        </p>
                        <h2 class="font-w600 mb-0">
                          ₹{dashboardData?.totalWithdrawal.toFixed(2)}
                        </h2>
                      </div>
                      <div class="dlab-swiper-circle">
                        <div className="img-icon text-warning">
                          <i class="bi bi-sort-up"></i>
                        </div>
                      </div>
                    </div>
                    <div class="card-body d-none d-sm-block p-0">
                      <div
                        id="widgetChart1"
                        class="chart-primary"
                        style={{ minHeight: "70px" }}
                      >
                        <div
                          id="apexchartskj2vn6y2"
                          class="apexcharts-canvas apexchartskj2vn6y2 apexcharts-theme-light"
                          style={{ width: "500px", height: "70px" }}
                        >
                          <img
                            alt="image"
                            width="auto"
                            src="/images/wavesvg.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-xl-3 col-6">
                <div class="card card-box bg-pink card pull-up">
                  <div class="card-header border-0 pb-0">
                    <div class="chart-num">
                      <p>
                        <i class="fa-solid fa-sort-down me-2"></i>
                        Refferal Income
                      </p>
                      <h2 class="font-w600 mb-0">
                        ₹{dashboardData?.refferalIncome.toFixed(2)}
                      </h2>
                    </div>

                    <div class="dlab-swiper-circle">
                      <div className="img-icon text-pink">
                        <i class="bi bi-bank"></i>
                      </div>
                    </div>
                  </div>
                  <div class="card-body d-none d-sm-block p-0">
                    <div
                      id="widgetChart1"
                      class="chart-primary"
                      style={{ minHeight: "70px" }}
                    >
                      <div id="apexchartskj2vn6y2">
                        <img
                          alt="image"
                          width="auto"
                          src="/images/wavesvg.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-9">
                <div class="row">
                  <div className="claerfix"></div>

                  <div class="col-xl-12 col-lg-12">
                    <div class="card pull-up">
                      <div class="card-header ">
                        <h4 class="card-title">Referral Link</h4>
                      </div>
                      <div class="card-body">
                        <div class="basic-form">
                          <div class="form-wrapper trade-form">
                            {/* <label clsassName="mb-2">Your Referral URL</label> */}

                            <div class="input-group mb-3 ">
                              <span class="input-group-text">Link</span>
                              <input
                                class="form-control"
                                type="text"
                                value={`${document.location.protocol}//${document.location.host}/user/register/${dashboardData?.userId}`}
                              />
                              <button
                                class="input-group-text copy-icons search_icon"
                                value={`${document.location.protocol}//${document.location.host}/user/register/${dashboardData?.userId}`}
                                onClick={Copy}
                              >
                                <i class="bi bi-copy"></i>
                              </button>
                            </div>




                            
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3 ">
                <div class="card hauto">
                  <div class="social-graph-wrapper widget-twitter">
                    <span class="s-icon">
                      <i class="bi bi-people-fill"></i>
                    </span>
                  </div>
                  <div class="row">
                    <div class="col-6 border-end">
                      <div class="pt-3 pb-3 ps-0 pe-0 text-center text-light">
                        <h4 class="m-1 text-light">
                          <span class="counter">
                            {teamMembers.length < 10
                              ? "0" + teamMembers?.length
                              : teamMembers?.length}
                          </span>
                        </h4>
                        <p class="m-0 text-white">Total Members</p>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="pt-3 pb-3 ps-0 pe-0 text-center text-light">
                        <h4 class="m-1 text-light">
                          <span class="counter">
                            {onlineMembers?.length < 10
                              ? "0" + onlineMembers?.length
                              : onlineMembers?.length}
                          </span>
                        </h4>
                        <p class="m-0 text-white">Online Members</p>
                      </div>
                    </div>
                  </div>

                  
                </div>

                <div class="col-xl-12 col-sm-12">
   <Link to="/user/spinwheel/spin">
   <div class="card card-box pull-up">
      
      <div class="card-body text-center pt-3">
         <div class="icon-box icon-box-sm bg-primary"><i class="bi bi-currency-dollar text-light fs-20"></i></div>
         
         <div class="count-num text-light  mt-3">01</div>
         <div class="mt-1 text-light">Spin Wheel</div>
      </div>
   </div>

   </Link>
</div>


              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashbord;
