import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import token from "../../../Helper/Token";
import { toast } from "react-toastify";
import { isNumeric } from "../../../Helper/function";

export default function AdminDashbord() {
  const [aviatorData, setAviatorData] = useState({
    aviatorUpperLimit: "",
    aviatorProbability: "",
  });

  const [postAviatorData, setPostAviatorData] = useState({
    aviatorUpperLimit: "",
    probability: "",
  });

  const [validatePostAviatorData, setValidatePostAviatorData] = useState({
    aviatorUpperLimit: "",
    aviatorProbability: "",
  });

  const [toggleForm, setToggleForm] = useState({
    aviatorUpperLimit: false,
    aviatorProbability: false,
  });

  const [aviatorBetsData, setAviatorBetsData] = useState({
    totalBids: 0,
    totalLoss: 0,
    totalRevenue: 0,
    todayBids: 0,
    todayLoss: 0,
    todayRevenue: 0,
  });

  const [dashboardData, setDashboardData] = useState({
    totalUsers: 0,
    totalBets: 0,
    totalWinnings: 0,
    totalLoss: 0,
    todayRegisteredUsers: 0,
    todayTotalBets: 0,
    todayTotalWinnings: 0,
    todayTotalLoss: 0,
    totalWithdrawal: 0,
    totalPendingWithdrawal: 0,
    totalRejectedWithdrawal: 0,
    totalDiposit: 0,
    totalPendingDiposit: 0,
    totalRejctedDiposit: 0,
    todayTotalWithdrawal: 0,
    todayTotalPendingWithdrawal: 0,
    todayTotalRejectedWithdrawal: 0,
    todayTotalDeposit: 0,
    todayTotalPendingDeposit: 0,
    todayTotalRejectedDeposit: 0,
    totalBlockedUsers: 0,
    totalUnblockedUsers: 0,
    kmtRate: 0,
    usdtRate: 0,
  });
  const [usdtRate, setUSDTRate] = useState(0);
  const [validateUSDTRate, setValidateUSDTRate] = useState("");
  const [kmtRate, setKMTRate] = useState(0);
  const [validateKMTRate, setValidateKMTRate] = useState("");

  useEffect(() => {
    fetchAviatorData();
  }, []);



  useEffect(() => {
    token
      .get("/aviator/bet-data")
      .then((res) => {
        if (res.data.status == "success") {
          setAviatorBetsData(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  useEffect(() => {
    token
      .get("/admin/get-dashboard-data")
      .then((res) => {
        if (res.data.status == "success") {
          setDashboardData(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }, []);

  function updateAviatorUpperLimit(e) {
    e.preventDefault();
    token
      .put("aviator/upadte-upper-limit", postAviatorData)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchAviatorData();
          document.getElementById("close-aviator-model").click();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function updateAviatorProbability(e) {
    e.preventDefault();
    token
      .put("/aviator/upadte-probability", postAviatorData)
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          fetchAviatorData();
          document.getElementById("close-aviator-model").click();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function fetchAviatorData() {
    token
      .get("/aviator/get-upper-limit-&-probability")
      .then((res) => {
        if (res.data.status == "success") {
          setAviatorData(res?.data?.data);
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })
      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function updateUSDTrate(e) {
    e.preventDefault();
    token
      .put("/admin/update-usdt-rate", { usdtRate: usdtRate })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          setDashboardData({ ...dashboardData, usdtRate: usdtRate });
          document.getElementById("closeUSDTFromBtn").click();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  function updateKMTrate(e) {
    e.preventDefault();
    token
      .put("/admin/update-kmt-rate", { kmtRate: kmtRate })
      .then((res) => {
        if (res.data.status == "success") {
          toast.success(res?.data?.message, {
            position: "top-left",
            autoClose: 900,
          });
          setDashboardData({ ...dashboardData, kmtRate: kmtRate });

          document.getElementById("closeKMTFromBtn").click();
        } else {
          toast.error(res?.data?.message, {
            position: "top-right",
            autoClose: 900,
          });
        }
      })

      .catch((err) => {
        toast.error("Internal Server Error.", {
          position: "top-right",
          autoClose: 900,
        });
      });
  }

  return (
    <>
      <div id="main-wrappe">
        <div class="page-titles">
          <div class="sub-dz-head">
            <div class="d-flex align-items-center dz-head-title">
              <h2 class="text-white m-0">Dashboard</h2>
            </div>
          </div>
        </div>
        <div class="content-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-xl-9 col-xxl-8">
                <div class="row">
                  <div class="col-xl-4 col-sm-6 ">
                    <Link to={"/admin/all-users-report"}>
                      <div class="card banking-card pull-up pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Total Users</span>
                            <h4>
                              {" "}
                              {dashboardData?.totalUsers < 10
                                ? "0" + dashboardData?.totalUsers
                                : dashboardData?.totalUsers}
                            </h4>
                          </div>
                          <div class="setting bg-primary">
                            <i class="bi bi-people text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-xl-4 col-sm-6">
                    <Link to={"/admin/fund-request-report/1"}>
                      <div class="card banking-card pull-up pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Total Deposit</span>
                            <h4>
                              {" "}
                              {dashboardData?.totalDiposit < 10
                                ? "0" + dashboardData?.totalDiposit?.toFixed(2)
                                : dashboardData?.totalDiposit?.toFixed(2)}{" "}
                              INR
                            </h4>
                          </div>
                          <div class="setting bg-success">
                            <i class="bi bi-credit-card text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-xl-4 col-sm-12">
                    <Link to={"/admin/withdrawal-report/1"}>
                      <div class="card banking-card pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Total Withdraw</span>
                            <h4>
                              {" "}
                              {dashboardData?.totalWithdrawal < 10
                                ? "0" +
                                  dashboardData?.totalWithdrawal?.toFixed(2)
                                : dashboardData?.totalWithdrawal?.toFixed(
                                    2
                                  )}{" "}
                              INR
                            </h4>
                          </div>
                          <div class="setting bg-danger">
                            <i class="bi bi-bank2 text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-xl-4 col-sm-6">
                    <Link to={"/admin/all-users-report"}>
                      <div class="card banking-card pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Total Bet</span>
                            <h4>
                              {" "}
                              {dashboardData?.totalBets < 10
                                ? "0" + dashboardData?.totalBets
                                : dashboardData?.totalBets}
                            </h4>
                          </div>
                          <div class="setting bg-warning">
                            <i class="bi bi-people text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-xl-4 col-sm-6">
                    <Link to={"/admin/fund-request-report/1"}>
                      <div class="card banking-card pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Total Win</span>
                            <h4>
                              {" "}
                              {dashboardData?.totalWinnings < 10
                                ? "0" + dashboardData?.totalWinnings?.toFixed(2)
                                : dashboardData?.totalWinnings?.toFixed(2)}{" "}
                              INR
                            </h4>
                          </div>
                          <div class="setting bg-info">
                            <i class="bi bi-credit-card text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </Link>
                  </div>
                  <div class="col-xl-4 col-sm-12">
                    <Link to={"/admin/withdrawal-report/1"}>
                      <div class="card banking-card pull-up">
                        <div class="card-header border-0 flex-wrap pb-0">
                          <div class="revenue-date text-light">
                            <span>Total Loss</span>
                            <h4>
                              {dashboardData?.totalLoss < 10
                                ? "0" + dashboardData?.totalLoss?.toFixed(2)
                                : dashboardData?.totalLoss?.toFixed(2)}{" "}
                              INR
                            </h4>
                          </div>
                          <div class="setting bg-pink">
                            <i class="bi bi-bank2 text-light fs-18"></i>
                          </div>
                        </div>
                        <div class="card-body pb-0 custome-tooltip d-flex align-items-center"></div>
                      </div>
                    </Link>
                  </div>

                  <div class="col-lg-6 col-sm-12">
                    <div class="card">
                      <div class="card-header py-3 ">
                        <h3 class="card-title">Deposit Overview</h3>
                      </div>
                      <div class="card-body px-3">
                        <div class="basic-list-group">
                          <ul class="list-group ">
                            <Link to={"/admin/fund-request-report/0"}>
                              {" "}
                              <li class="list-group-item d-flex justify-content-between">
                                Pending Deposit
                                <strong class="">
                                  {" "}
                                  {dashboardData?.totalPendingDiposit < 10
                                    ? "0" +
                                      dashboardData?.totalPendingDiposit?.toFixed(
                                        2
                                      )
                                    : dashboardData?.totalPendingDiposit?.toFixed(
                                        2
                                      )}{" "}
                                  INR
                                </strong>
                              </li>
                            </Link>
                            <Link to={"/admin/fund-request-report/-1"}>
                              {" "}
                              <li class="list-group-item d-flex justify-content-between">
                                Rejected Deposit :
                                <strong class="">
                                  {" "}
                                  {dashboardData?.totalRejctedDiposit < 10
                                    ? "0" +
                                      dashboardData?.totalRejctedDiposit?.toFixed(
                                        2
                                      )
                                    : dashboardData?.totalRejctedDiposit?.toFixed(
                                        2
                                      )}{" "}
                                  INR
                                </strong>
                              </li>
                            </Link>
                            <li class="list-group-item d-flex justify-content-between">
                              Today Deposit:
                              <strong class="">
                                {" "}
                                {dashboardData?.todayTotalDeposit < 10
                                  ? "0" +
                                    dashboardData?.todayTotalDeposit?.toFixed(2)
                                  : dashboardData?.todayTotalDeposit?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                            <li class="list-group-item d-flex justify-content-between">
                              Today Pending Deposit
                              <strong class="">
                                {" "}
                                {dashboardData?.todayTotalPendingDeposit < 10
                                  ? "0" +
                                    dashboardData?.todayTotalPendingDeposit?.toFixed(
                                      2
                                    )
                                  : dashboardData?.todayTotalPendingDeposit?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                            <li class="list-group-item d-flex justify-content-between">
                              Today Rejected Deposit :
                              <strong class="">
                                {" "}
                                {dashboardData?.todayTotalRejectedDeposit < 10
                                  ? "0" +
                                    dashboardData?.todayTotalRejectedDeposit?.toFixed(
                                      2
                                    )
                                  : dashboardData?.todayTotalRejectedDeposit?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-12">
                    <div class="card">
                      <div class="card-header py-3 ">
                        <h3 class="card-title">Withdraw Overview</h3>
                      </div>
                      <div class="card-body px-3">
                        <div class="basic-list-group">
                          <ul class="list-group ">
                            <Link to={"/admin/withdrawal-report/0"}>
                              {" "}
                              <li class="list-group-item d-flex justify-content-between">
                                {" "}
                                Pending Withdraw
                                <strong class="">
                                  {" "}
                                  {dashboardData?.totalPendingWithdrawal < 10
                                    ? "0" +
                                      dashboardData?.totalPendingWithdrawal?.toFixed(
                                        2
                                      )
                                    : dashboardData?.totalPendingWithdrawal?.toFixed(
                                        2
                                      )}{" "}
                                  INR
                                </strong>
                              </li>
                            </Link>

                            <Link to={"/admin/withdrawal-report/-1"}>
                              <li class="list-group-item d-flex justify-content-between">
                                Rejected Withdraw :
                                <strong class="">
                                  {" "}
                                  {dashboardData?.totalRejectedWithdrawal < 10
                                    ? "0" +
                                      dashboardData?.totalRejectedWithdrawal?.toFixed(
                                        2
                                      )
                                    : dashboardData?.totalRejectedWithdrawal?.toFixed(
                                        2
                                      )}{" "}
                                  INR
                                </strong>
                              </li>
                            </Link>
                            <li class="list-group-item d-flex justify-content-between">
                              Today Withdraw:
                              <strong class="">
                                {" "}
                                {dashboardData?.todayTotalWithdrawal < 10
                                  ? "0" +
                                    dashboardData?.todayTotalWithdrawal?.toFixed(
                                      2
                                    )
                                  : dashboardData?.todayTotalWithdrawal?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                            <li class="list-group-item d-flex justify-content-between">
                              Today Pending Withdraw
                              <strong class="">
                                {" "}
                                {dashboardData?.todayTotalPendingWithdrawal < 10
                                  ? "0" +
                                    dashboardData?.todayTotalPendingWithdrawal?.toFixed(
                                      2
                                    )
                                  : dashboardData?.todayTotalPendingWithdrawal?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                            <li class="list-group-item d-flex justify-content-between">
                              Today Rejected Withdraw :
                              <strong class="">
                                {" "}
                                {dashboardData?.todayTotalRejectedWithdrawal <
                                10
                                  ? "0" +
                                    dashboardData?.todayTotalRejectedWithdrawal?.toFixed(
                                      2
                                    )
                                  : dashboardData?.todayTotalRejectedWithdrawal?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6 col-sm-12">
                    <div class="card">
                      <div class="card-header py-3 ">
                        <h3 class="card-title">Aviator Overview</h3>
                      </div>
                      <div class="card-body px-3">
                        <div class="basic-list-group">
                          <ul class="list-group ">
                            <Link to={"/admin/fund-request-report/0"}>
                              {" "}
                              <li class="list-group-item d-flex justify-content-between">
                                Total Bid
                                <strong class="">
                                  {aviatorBetsData?.totalBids < 10
                                    ? "0" + aviatorBetsData?.totalBids
                                    : aviatorBetsData?.totalBids}
                                </strong>
                              </li>
                            </Link>
                            <Link to={"/admin/fund-request-report/-1"}>
                              {" "}
                              <li class="list-group-item d-flex justify-content-between">
                                Total Loss
                                <strong class="">
                                  {aviatorBetsData?.totalLoss < 10
                                    ? "0" +
                                      aviatorBetsData?.totalLoss?.toFixed(2)
                                    : aviatorBetsData?.totalLoss?.toFixed(
                                        2
                                      )}{" "}
                                  INR
                                </strong>
                              </li>
                            </Link>
                            <li class="list-group-item d-flex justify-content-between">
                              Total revenue:
                              <strong class="">
                                {aviatorBetsData?.totalRevenue < 10
                                  ? "0" +
                                    aviatorBetsData?.totalRevenue?.toFixed(2)
                                  : aviatorBetsData?.totalRevenue?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                            <li class="list-group-item d-flex justify-content-between">
                              Today bid
                              <strong class="">
                                {aviatorBetsData?.todayBids < 10
                                  ? "0" + aviatorBetsData?.todayBids
                                  : aviatorBetsData?.todayBids}
                              </strong>
                            </li>
                            <li class="list-group-item d-flex justify-content-between">
                              Today loss :
                              <strong class="">
                                {aviatorBetsData?.todayLoss < 10
                                  ? "0" + aviatorBetsData?.todayLoss?.toFixed(2)
                                  : aviatorBetsData?.todayLoss?.toFixed(2)}{" "}
                                INR
                              </strong>
                            </li>
                            <li class="list-group-item d-flex justify-content-between">
                              Today revenue :
                              <strong class="">
                                {aviatorBetsData?.todayRevenue < 10
                                  ? "0" +
                                    aviatorBetsData?.todayRevenue?.toFixed(2)
                                  : aviatorBetsData?.todayRevenue?.toFixed(
                                      2
                                    )}{" "}
                                INR
                              </strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-3 col-xxl-4">
                <div class="row">
                  <div class="col-xl-12 col-lg-6 col-md-12">
                    <div class="card card-box">
                      <div class="card-header no-bg border-0 pb-0">
                        <h2 class="card-title mb-0">Users Overview</h2>
                      </div>
                      <div class="card-body">
                        <div class="d-flex mb-3">
                          <div class="icon-box bg-primary me-2 ">
                            <i class="bi bi-person-add text-white fs-24"></i>
                          </div>
                          <div class="ps-2 w-100 flex-1">
                            <h6 class="mt-2 mb-0">
                              {dashboardData?.todayRegisteredUsers < 10
                                ? "0" + dashboardData?.todayRegisteredUsers
                                : dashboardData?.todayRegisteredUsers}{" "}
                            </h6>

                            <div class="mt-1">
                              <span class="text-light">
                                {" "}
                                Today's Registered Users
                              </span>
                            </div>
                          </div>
                        </div>

                        <Link to={"/admin/all-users-report/1"}>
                          <div class="d-flex mb-3">
                            <div class="icon-box bg-success me-2">
                              <i class="bi bi-person-check text-white fs-24"></i>
                            </div>
                            <div class="ps-2 w-100 flex-1">
                              <h6 class="mt-2 mb-0">
                                {" "}
                                {dashboardData?.totalUnblockedUsers < 10
                                  ? "0" + dashboardData?.totalUnblockedUsers
                                  : dashboardData?.totalUnblockedUsers}{" "}
                              </h6>

                              <div class="mt-1">
                                <span class="text-light">
                                  Total Active Users{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>

                        <Link to={"/admin/all-users-report/0"}>
                          <div class="d-flex mb-3">
                            <div class="icon-box bg-danger me-2">
                              <i class="bi bi-person-dash text-white fs-24"></i>
                            </div>
                            <div class="ps-2 w-100 flex-1">
                              <h6 class="mt-2 mb-0">
                                {" "}
                                {dashboardData?.totalBlockedUsers < 10
                                  ? "0" + dashboardData?.totalBlockedUsers
                                  : dashboardData?.totalBlockedUsers}{" "}
                              </h6>

                              <div class="mt-1">
                                <span class="text-light">
                                  {" "}
                                  Total Blocked Users{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-sm-6">
                    <div
                      class="card card-box pull-up"
                      onClick={() => {
                        document.getElementById("openUSDTFromBtn").click();
                      }}
                    >
                      <div class="card-header no-bg border-0 pb-0">
                        <h6 class="mb-0 m-auto">USDT</h6>
                      </div>
                      <div class="card-body text-center pt-3">
                        <div class="icon-box icon-box-sm bg-primary">
                          <i class="bi bi-currency-dollar text-light fs-20"></i>
                        </div>
                        <div class="mt-3 text-light">USDT Price</div>
                        <div class="count-num text-light  mt-1">
                          {" "}
                          {dashboardData?.usdtRate < 10
                            ? Number("0" + dashboardData?.usdtRate).toFixed(2)
                            : Number(dashboardData?.usdtRate).toFixed(2)}{" "}
                          / INR{" "}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-xl-6 col-sm-6">
                    <div
                      class="card pull-up"
                      onClick={() => {
                        document.getElementById("openKMTFromBtn").click();
                      }}
                    >
                      <div class="card-header no-bg border-0 pb-0">
                        <h6 class="m-auto mb-0">Tron </h6>
                      </div>
                      <div class="card-body text-center pt-3">
                        <div class="icon-box icon-box-sm bg-danger">
                          <i class="bi bi-currency-dollar text-light fs-20"></i>
                        </div>
                        <div class="mt-3 text-light">Tron Price</div>
                        <div class="count-num text-light mt-1">
                          {" "}
                          {dashboardData?.kmtRate < 10
                            ? Number("0" + dashboardData?.kmtRate)?.toFixed(2)
                            : Number(dashboardData?.kmtRate)?.toFixed(2)}{" "}
                          / INR{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-sm-6">
                    <div
                      class="card pull-up"
                      onClick={() => {
                        document.getElementById("open-aviator-model").click();
                        setToggleForm({
                          aviatorUpperLimit: false,
                          aviatorProbability: true,
                        });
                      }}
                    >
                      <div class="card-header no-bg border-0 pb-0">
                        <h6 class="m-auto mb-0">Aviator Probability </h6>
                      </div>
                      <div class="card-body text-center pt-3">
                        <div class="icon-box icon-box-sm bg-danger">
                          <i class="bi bi-currency-dollar text-light fs-20"></i>
                        </div>
                        <div class="mt-3 text-light">Probability</div>
                        <div class="count-num text-light mt-1">
                          {aviatorData?.aviatorProbability}%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-6 col-sm-6">
                    <div
                      class="card pull-up"
                      onClick={() => {
                        document.getElementById("open-aviator-model").click();
                        setToggleForm({
                          aviatorUpperLimit: true,
                          aviatorProbability: false,
                        });
                      }}
                    >
                      <div class="card-header no-bg border-0 pb-0">
                        <h6 class="m-auto mb-0">Aviator Upper Limit </h6>
                      </div>
                      <div class="card-body text-center pt-3">
                        <div class="icon-box icon-box-sm bg-danger">
                          <i class="bi bi-currency-dollar text-light fs-20"></i>
                        </div>
                        <div class="mt-3 text-light">Upper Limit</div>
                        <div class="count-num text-light mt-1">
                          {aviatorData?.aviatorUpperLimit}X
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
        id="openUSDTFromBtn"
        hidden
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Update USDT Rate
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={updateUSDTrate}>
                <div class="mb-3">
                  <label htmlFor="usdtRate" class="form-label text-light">
                    Today's USDT Rate
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="usdtRate"
                    onChange={(e) => {
                      if (isNumeric(e.target.value)) {
                        setUSDTRate(e.target.value);
                        setValidateUSDTRate("");
                      } else {
                        setValidateUSDTRate("Only numbers are allowed");
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.value.length - 1
                        );
                      }
                    }}
                  />
                  <p className="text-danger text-center">{validateUSDTRate}</p>
                </div>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
                <button
                  type="button"
                  id="closeUSDTFromBtn"
                  class="btn btn-secondary d-none"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal2"
        id="open-aviator-model"
        hidden
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                {toggleForm.aviatorProbability ? " Aviator Probability" : null}
                {toggleForm.aviatorUpperLimit ? " Aviator Upper Limit" : null}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              {toggleForm.aviatorProbability ? (
                <form>
                  <div class="mb-3">
                    <label htmlFor="usdtRate" class="form-label text-light">
                      Today's Aviator Probability
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      defaultValue={aviatorData.aviatorProbability}
                      id="usdtRate"
                      onChange={(e) => {
                        if (isNumeric(e.target.value)) {
                          setPostAviatorData({
                            ...postAviatorData,
                            probability: e.target.value,
                          });

                          setValidatePostAviatorData({
                            ...validatePostAviatorData,
                            aviatorProbability: "",
                          });
                        } else {
                          setValidatePostAviatorData({
                            ...validatePostAviatorData,
                            aviatorProbability: "Only numbers are allowed",
                          });

                          e.target.value = e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          );
                        }
                      }}
                    />
                    <p className="text-danger text-center">
                      {validatePostAviatorData?.aviatorProbability}
                    </p>
                  </div>
                  <button
                    class="btn btn-primary"
                    onClick={updateAviatorProbability}
                  >
                    Submit
                  </button>
                </form>
              ) : null}

              {toggleForm.aviatorUpperLimit ? (
                <form>
                  <div class="mb-3">
                    <label htmlFor="usdtRate" class="form-label text-light">
                      Today's Aviator Upper Limit
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      defaultValue={aviatorData.aviatorUpperLimit}
                      id="usdtRate"
                      onChange={(e) => {
                        if (isNumeric(e.target.value)) {
                          setPostAviatorData({
                            ...postAviatorData,
                            upperLimit: e.target.value,
                          });

                          setValidatePostAviatorData({
                            ...validatePostAviatorData,
                            aviatorUpperLimit: "",
                          });
                        } else {
                          setValidatePostAviatorData({
                            ...validatePostAviatorData,
                            aviatorUpperLimit: "Only numbers are allowed",
                          });

                          e.target.value = e.target.value.slice(
                            0,
                            e.target.value.length - 1
                          );
                        }
                      }}
                    />
                    <p className="text-danger text-center">
                      {validatePostAviatorData?.aviatorUpperLimit}
                    </p>
                  </div>
                  <button
                    class="btn btn-primary"
                    onClick={updateAviatorUpperLimit}
                  >
                    Submit
                  </button>
                </form>
              ) : null}
            </div>

            <button
              type="button"
              class="btn btn-secondary"
              id="close-aviator-model"
              data-bs-dismiss="modal"
              hidden
            >
              Close
            </button>
          </div>
        </div>
      </div>

      <button
        type="button"
        class="btn btn-primary d-none"
        data-bs-toggle="modal"
        data-bs-target="#kmtModel"
        id="openKMTFromBtn"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="kmtModel"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">
                Update Tron Rate
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <form onSubmit={updateKMTrate}>
                <div class="mb-3">
                  <label htmlFor="usdtRate" class="form-label text-light">
                    Today's Tron Rate
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="usdtRate"
                    onChange={(e) => {
                      if (isNumeric(e.target.value)) {
                        setKMTRate(e.target.value);

                        setValidateKMTRate("");
                      } else {
                        setValidateKMTRate("Only numbers are allowed");
                        e.target.value = e.target.value.slice(
                          0,
                          e.target.value.length - 1
                        );
                      }
                    }}
                  />
                  <p className="text-danger text-center">{validateKMTRate}</p>
                </div>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
                <button
                  type="button"
                  id="closeKMTFromBtn"
                  class="btn btn-secondary d-none"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
